<template>
  <div class="home" :class="{ 'page-container': isMobile }">
    <!-- Section Banner Start-->
    <Carousel />
    <!-- Section Banner End-->

    <div
      v-intersect.once="onIntersectSloganHomeBlock"
      class="section-wrapper mt-md-8"
    >
      <div class="content-wrapper">
        <v-scroll-x-transition>
          <div
            v-if="isIntersectingSloganHome"
            class="text-h6 text-md-h4 text-center"
            style="
              transition-delay: 0.5s !important;
              transition-duration: 0.5s !important;
            "
            v-html="$t('slogan.home')"
          ></div>
        </v-scroll-x-transition>
      </div>
    </div>

    <!-- Section Our Expertise Start -->
    <div class="section-wrapper">
      <div class="content-wrapper">
        <Content2
          :title="$t('ourExpertise[0].title')"
          :description="$t('ourExpertise[0].description')"
        ></Content2>

        <div v-intersect.once="onIntersectExpertiseList">
          <v-slide-y-transition
            :group="true"
            tag="div"
            class="row my-12 no-gutters"
          >
            <template v-for="(item, index) in expertiseList">
              <v-col
                v-if="isIntersectingExpertiseList"
                :key="index"
                cols="6"
                md="3"
                :style="`
                  transition-delay: 0.${5 + index}s !important;
                  transition-duration: 0.5s !important;`"
              >
                <div class="text-center px-2">
                  <img
                    :width="isMobile ? '70' : ''"
                    :src="`${shared.BASE_URL}img/svg/${item.svg}.svg`"
                  />
                  <div
                    class="font-weight-bold mt-md-6 text-subtitle-2 text-lg-subtitle-1 text-center"
                    v-html="$t(`ourExpertise[1].${item.text}`)"
                  ></div>
                </div>
              </v-col>
            </template>
          </v-slide-y-transition>
        </div>

        <div
          v-intersect.once="onIntersectViewAllButton"
          class="text-center pt-6"
        >
          <v-slide-y-transition>
            <v-btn
              v-if="isIntersectingViewAllButton"
              tile
              depressed
              :min-width="isMobile ? 153 : 182"
              :min-height="isMobile ? 39 : 46"
              color="primary"
              class="text-capitalize font-weight-black title--text"
              @click="toOurExpertise"
              :style="`
                  transition-delay: 0.5s !important;
                  transition-duration: 0.5s !important;`"
              >{{ $t('text.viewAll') }}</v-btn
            >
          </v-slide-y-transition>
        </div>
      </div>
    </div>
    <!-- Section Our Expertise End -->

    <!-- Section Why Choose Us Start -->
    <div class="section-wrapper section-why-choose-us">
      <div class="content-wrapper">
        <Content1
          :title="$t('reasonToChooseUs[0].title')"
          :description="$t('reasonToChooseUs[0].description')"
        ></Content1>

        <div
          v-intersect.once="{
            handler: onIntersectReasonToChooseUsList,
            options: {
              threshold: [1]
            }
          }"
        >
          <v-scale-transition
            :group="true"
            tag="div"
            class="row no-gutters pt-8 mt-6 pa-md-10 reason-to-choose-us-list"
          >
            <template v-for="(i, index) in reasonToChooseUsList">
              <v-col
                v-if="isIntersectingReasonToChooseUsList"
                :key="index"
                cols="12"
                md="6"
                class="pa-md-12"
                :class="{ 'item-wrapper divider': !isMobile }"
                :style="`
                  transition-delay: 0.${4 + index}s !important;
                  transition-duration: 0.5s !important;`"
              >
                <v-row no-gutters class="px-md-6 mb-6 mb-md-0">
                  <v-col cols="2" md="12"
                    ><img
                      :height="isMobile ? 45 : ''"
                      :src="`${shared.BASE_URL}img/svg/${i.svg}.svg`"
                  /></v-col>

                  <v-col cols="10" md="12">
                    <div
                      class="font-weight-bold my-md-4 text-body-1 text-md-h6 mb-3 mb-md-0"
                    >
                      {{ $t(`reasonToChooseUs[1].${i.text}`) }}
                    </div>
                    <div
                      class="d-md-none label--text text-body-2 text-lg-body-1"
                    >
                      {{ $t(`reasonToChooseUs[2].${i.text}`) }}
                    </div>
                  </v-col>
                  <v-col cols="2" md="0"></v-col>
                  <v-col
                    cols="10"
                    md="12"
                    class="d-none d-md-flex label--text text-body-2 text-lg-body-1"
                  >
                    {{ $t(`reasonToChooseUs[2].${i.text}`) }}
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-scale-transition>
        </div>
      </div>
    </div>
    <!-- Section Why Choose Us End -->

    <!-- Section Contact Us Now Start -->
    <ContactUsNow></ContactUsNow>
    <!-- Section Contact Us Now End -->
  </div>
</template>

<script>
import ContactUsNow from '@/components/section/ContactUsNow'
import Content2 from '@/components/content/Content-2'
import Carousel from '@/components/home/Carousel'

import { ROUTE_NAME, SHARED } from '@/constants'

export default {
  name: 'Home',
  components: {
    ContactUsNow,
    Content2,
    Carousel
  },
  data: () => ({
    shared: SHARED,
    expertiseList: [
      {
        svg: 'rook',
        text: 1
      },
      {
        svg: 'business-and-trade',
        text: 2
      },
      {
        svg: 'group',
        text: 3
      },
      {
        svg: 'broken',
        text: 4
      }
    ],
    reasonToChooseUsList: [
      {
        svg: 'star-group',
        text: 1
      },
      {
        svg: 'rocket',
        text: 2
      },
      {
        svg: 'business-presentation',
        text: 3
      },
      {
        svg: 'network',
        text: 4
      }
    ],
    isIntersectingSloganHome: false,
    isIntersectingExpertiseList: false,
    isIntersectingViewAllButton: false,
    isIntersectingReasonToChooseUsList: false
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    onIntersectReasonToChooseUsList(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingReasonToChooseUsList = isIntersecting
      }
    },
    onIntersectViewAllButton(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingViewAllButton = isIntersecting
      }
    },
    onIntersectExpertiseList(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingExpertiseList = isIntersecting
      }
    },
    onIntersectSloganHomeBlock(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingSloganHome = isIntersecting
      }
    },
    toOurExpertise() {
      this.$router.push({ name: ROUTE_NAME.OUR_EXPERTISE })
    }
  }
}
</script>
<style lang="scss">
.section-why-choose-us {
  .reason-to-choose-us-list {
    .item-wrapper:nth-child(odd) {
      padding-left: 10rem !important;
    }
  }
  .divider {
    border-right: 1px solid var(--v-greyDivider-base);
    border-top: 1px solid var(--v-greyDivider-base);
    &:nth-child(2),
    &:nth-child(4) {
      border-right: unset !important;
    }

    &:nth-child(1),
    &:nth-child(2) {
      border-top: unset !important;
    }
  }
}

@media (max-width: 1903px) {
  .banner-bottom-card {
    .banner-text {
      font-size: 1rem;
      &::first-letter {
        font-size: 3rem;
      }
    }
  }
}

@media (max-width: 1263px) {
  .banner-bottom-card {
    .banner-text {
      font-size: 0.8rem;
      &::first-letter {
        font-size: 2.5rem;
      }
    }
    .divider {
      &::after {
        width: 46px;
      }
    }
  }
}
@media (max-width: 959px) {
  .banner-container {
    height: unset;
  }
  .banner-bottom-card {
    .banner-text {
      font-size: 0.725rem;
      &::first-letter {
        font-size: 2rem;
      }
    }
    .divider {
      &::after {
        width: 36px;
      }
    }
  }
}
@media (max-width: 599px) {
  .banner-bottom-card {
    .banner-text {
      font-size: 0.65rem;
      &::first-letter {
        font-size: 1.5rem;
      }
    }
    .divider {
      &::after {
        width: 18px;
      }
    }
  }
}
</style>
