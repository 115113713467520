<template>
  <v-carousel
    v-intersect.once="onIntersectSectionBanner"
    remove-default-arrows
    hide-delimiter-background
    vertical-delimiters
    cycle
    :height="carouselHeight"
    class="banner-carousel"
  >
    <v-carousel-item content-class="main-item carousel-item-content">
      <v-slide-y-transition>
        <v-img
          v-if="isIntersectingSectionBanner"
          width="100%"
          height="100%"
          class="banner-item image"
          :src="`${shared.BASE_URL}img/png/home-1.png`"
          alt="banner"
          style="
            transition-delay: 0.1s !important;
            transition-duration: 1.5s !important;
          "
        ></v-img>
      </v-slide-y-transition>

      <v-scroll-x-transition>
        <v-card
          v-intersect.once="onIntersectHomeBannerContents"
          v-if="isIntersectingSectionBanner"
          flat
          tile
          color="tertiary"
          class="px-lg-6 px-xl-12 d-flex align-center banner-item card banner-bottom-card"
          style="
            transition-delay: 0.6s !important;
            transition-duration: 1.5s !important;
          "
        >
          <v-slide-x-transition
            :group="true"
            tag="div"
            class="w-100 d-flex justify-space-around px-6"
          >
            <template v-for="(i, index) in keySkills">
              <div
                v-if="isIntersectingHomeBannerContents"
                :key="index"
                :style="`
                  transition-delay: 1.${index + 2}s !important;
                  transition-duration: 0.5s !important;`"
              >
                <div
                  v-if="i > 0"
                  class="banner-text surface--text my-md-2 font-weight-bold"
                >
                  {{ $t(`homeBannerContents[1].${i}`) }}
                </div>

                <div v-else class="divider pt-3 pt-sm-2 pt-md-4"></div>
              </div>
            </template>
          </v-slide-x-transition>
        </v-card>
      </v-scroll-x-transition>
    </v-carousel-item>

    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      content-class="carousel-item-content"
    >
      <v-img :src="item.path" width="100%" height="100%"></v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { SHARED } from '@/constants'
import axios from 'axios'

export default {
  name: 'Carousel',
  data: () => ({
    shared: SHARED,
    keySkills: [1, 0, 2, 0, 3],
    isIntersectingSectionBanner: false,
    isIntersectingHomeBannerContents: false,
    items: []
  }),
  computed: {
    carouselHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '100vh' : 'auto'
    }
  },
  async created() {
    await this.getBanners()
  },
  methods: {
    onIntersectHomeBannerContents(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingHomeBannerContents = isIntersecting
      }
    },
    onIntersectSectionBanner(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingSectionBanner = isIntersecting
      }
    },
    async getBanners() {
      try {
        const response = await axios.get('/banner/banners.json')

        if (response.status === 200) {
          this.items = response.data
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss">
.banner-carousel {
  .v-carousel__controls {
    right: 0 !important;
  }

  .carousel-item-content {
    width: 100%;
    height: 100%;
  }
}

.main-item {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  .banner-item {
    &.image {
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 13;
    }
    &.card {
      grid-column-start: 2;
      grid-column-end: 12;
      grid-row-start: 11;
      grid-row-end: 13;
    }
  }
}

.banner-bottom-card {
  .banner-text {
    font-size: 1.75rem;
    &::first-letter {
      color: var(--v-primary-base);
      font-size: 3.75rem;
      font-family: 'Cormorant', sans-serif !important;
    }
  }
  .description {
    line-height: 1rem !important;
  }

  .divider {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      border-top: 1px solid var(--v-primary-base);
      width: 66px;
      transform: rotate(110deg);
      transform-origin: 0% 0%;
      opacity: 0.5;
    }

    &:first-child::before {
      border: unset !important;
    }
  }
}
</style>
