<template>
  <div v-intersect.once="onIntersect" class="content-2 py-md-10">
    <v-row no-gutters justify="end" align="center">
      <v-col cols="12" md="3" class="mb-5">
        <v-scroll-x-transition>
          <div
            v-if="isIntersecting"
            style="
              transition-delay: 0.5s !important;
              transition-duration: 0.5s !important;
            "
            class="text-h4 text-md-h3 font-cormorant title--text font-weight-bold text-capitalize"
            v-html="title"
          ></div>
        </v-scroll-x-transition>
      </v-col>
      <v-col cols="12" md="8">
        <v-scroll-x-transition>
          <p
            v-if="isIntersecting"
            style="
              transition-delay: 1s !important;
              transition-duration: 0.5s !important;
            "
            class="label--text px-md-12 text-body-2 text-lg-body-1 mb-0 description"
            v-html="description"
          ></p>
        </v-scroll-x-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Content2',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isIntersecting: false
  }),
  methods: {
    onIntersect(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersecting = isIntersecting
      }
    }
  }
}
</script>

<style lang="scss">
.content-2 {
  .description {
    strong {
      color: var(--v-title-base);
    }
  }
}
</style>
